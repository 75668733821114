import React, { useDebugValue, useEffect, useState } from "react";
import "../../App.css";

import SideMenu from "../../Components/SideMenu";
import Footer from "../../Components/Footer";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Alert from "../../Components/Alert";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AWS from "../../Constants/aws-config";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const EditNews = () => {
  //set
  const { newsID } = useParams();
  const [newsData, setNewsData] = useState([]);

  //edit
  const [newsTitle, setNewsTitle] = useState("");
  const [newsContent, setNewsContent] = useState("");
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState("");

  const [initialnewsImage, setInitialNewsImage] = useState(""); //initial news image from database
  const [updatedNewsImage, setUpdatedNewsImage] = useState(null); //updated image
  const [previewImage, setPreviewImage] = useState(null); //to display the preview
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getNews();
  }, []);

  const getNews = () => {
    fetch(`${API_URL}/getnewsbyid?newsID=${newsID}`)
      .then(async (response) => {
        const jsonRes = await response.json();
        const newsData = jsonRes.data;
        //setNewsData(newsData);
        setNewsTitle(newsData.newsTitle);
        setNewsContent(newsData.newsContent);
        setInitialNewsImage(newsData.newsImage);
        setPreviewImage(newsData.newsImage);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching news", error);
        setLoading(false);
      });
  };

  const handleContentChange = (value) => {
    setNewsContent(value);
  };

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    // check if user cancel the file input
    if (!imageFile) {
      return;
    }
    setUpdatedNewsImage(imageFile);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const onSubmitHandler = (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("newsTitle", newsTitle);
    formData.append("newsContent", newsContent);
    formData.append("initialNewsImage",initialnewsImage)
    formData.append("updatedNewsImage", updatedNewsImage);

    //update content to db
    // const payload = {
    //   newsTitle: newsTitle,
    //   newsContent: newsContent,
    //   newsImage: updatedNewsImage
    //     ? "https://dcapp-bucket.s3.ap-southeast-1.amazonaws.com/" +
    //       updatedNewsImage.name
    //     : initialnewsImage,
    // };

    fetch(`${API_URL}/updateNews?id=${newsID}`, {
      method: "PUT",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage("HTTP Upload Error");
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setIsError(false);
        setMessage("File uploaded successfully");
        setLoading(false);
      })
      .catch((error) => {
        setIsError(true);
        setMessage("Upload Error");
        console.error("Upload error:", error);
        setLoading(false);
      })
      .finally(() => {
        setUpdatedNewsImage(null); //reset state
      });
  };

  const getMessage = () => {
    const status = isError ? `Error` : `Success:`;
    return status + message;
  };
  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div style={({ height: "100vh" }, { display: "flex" })}>
      <SideMenu />
      {/* Main content */}
      <div className="form-container">
        <Title title={"Edit News"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}
        <section className="content">
          {/* <!-- general form elements --> */}
          <div class="card card-primary">
            {/* <!-- form start --> */}
            <form onSubmit={onSubmitHandler}>
              <div class="card-body">
                <div class="form-group">
                  <label htmlFor="title">News Title </label>
                  <input
                    onChange={(e) => setNewsTitle(e.target.value)}
                    type="text"
                    class="form-control"
                    id="title"
                    placeholder="Enter news title"
                    value={newsTitle}
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="image-input">File input</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input
                        id="image-input"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                  {previewImage && (
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{ maxWidth: "50%", marginTop: "10px" }}
                    />
                  )}
                </div>
                <div class="form-group">
                  <label for="content">Content</label>
                  {/* <textarea
                    onChange={(e) => setNewsContent(e.target.value)}
                    type="text"
                    class="form-control"
                    id="content"
                    rows="4"
                    value={newsContent}
                  /> */}
                  <ReactQuill
                    theme="snow"
                    value={newsContent}
                    onChange={handleContentChange}
                  />
                </div>
              </div>
              {/* <!-- /.card-body --> */}

              <div class="card-footer">
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
              </div>
              {/* <span style={{ color: isError ? "red" : "green" }}>
                {message ? getMessage() : null}
              </span> */}
            </form>
          </div>
          {/* <!-- /.card --> */}

          {/* <!-- general form elements --> */}
        </section>
      </div>
    </div>
  );
};
export default EditNews;
