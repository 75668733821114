import React, { useState, useEffect } from "react";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Alert from "../../Components/Alert";
import Table from "../../Components/Table";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const ManageUser = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = () => {
    fetch(`${API_URL}/getAllUsers`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
          setLoading(false);
        }
        return response.json();
      })
      .then((usersData) => {
        setUsersData(usersData);
        console.log(usersData);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching users", error);
        setLoading(false);
      });
  };

  const columns = [
    {
      field: "userID",
      headerName: "ID",
      width: 80,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phoneNum",
      headerName: "Phone",
      width: 200,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "memberType",
      headerName: "Member Type",
      renderCell: (params) => {
        if (params.row.members && params.row.members.length > 0) {
          return params.row.members[0].memberType;
        }
        return "N/A"; // Or some other default value for empty member types
      },

      width: 250,
    },
    {
      field: "birthday",
      headerName: "Date of Birth",
      selector: (params) => {
        const birthdayDate = new Date(params.row.birthday);
        return birthdayDate.toLocaleDateString();
      },
      width: 200,
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div
      style={{ height: "100%", display: "flex", backgroundColor: "#f4f6f9" }}
    >
      <SideMenu />
      {/* Main content */}
      <div className="form-container">
        <Title title={"Manage User"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}
        <section className="content">
          <div className="container-fluid">
            <Table columns={columns} rows={usersData} id={"userID"}></Table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ManageUser;
