import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const Table = (props) => {
  const { rows, columns, id, rowHeight, checkbox, getSelectedRow } = props;
  function getRowId(row) {
    return row[id];
  }
  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        getRowId={getRowId}
        pageSizeOptions={[10, 50, 100]}
        rowHeight={rowHeight}
        slots={{ toolbar: GridToolbar }}
        scrollbarSize={20}
        sx={{ height: 1 / 2 }}
        onRowSelectionModelChange={getSelectedRow}
        checkboxSelection={checkbox}
      />
    </div>
  );
};

export default Table;
