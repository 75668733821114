import React, { useEffect, useState } from "react";
import "../../App.css";
import Footer from "../../Components/Footer";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Alert from "../../Components/Alert";
import SideMenu from "../../Components/SideMenu";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const AddFAQ = () => {
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [catData, setCatData] = useState([]);
  const [category, setCategory] = useState(null);
  const [question, setQuestion] = useState(null);
  const [answer, setAnswer] = useState(null);

  const editorConfig = {
    height: 500,
    menubar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
      "undo redo | formatselect | " +
      "bold italic backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
  };

  useEffect(() => {
    getFaqCategories();
  }, []);

  const getFaqCategories = () => {
    setLoading(true);
    fetch(`${API_URL}/getAllFaqCategories`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((faqCategories) => {
        setCatData(faqCategories.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching  category data", error);
        setLoading(false);
      });
  };

  const handleEditorChange = (content, editor) => {
    setAnswer(content);
  };

  const onSubmitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    const payload = {
      faqCategoriesID: category,
      question: question,
      answer: answer,
    };

    fetch(`${API_URL}/addFaqContent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage("HTTP Upload Error");
          setLoading(false);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        setIsError(false);
        setMessage("File uploaded successfully");
        setLoading(false);
      })
      .catch((error) => {
        setIsError(true);
        setMessage("Upload Error");
        console.error("Upload error:", error);
        setLoading(false);
      });
  };

  const getMessage = () => {
    const status = isError ? `Error` : `Success:`;
    return status + message;
  };

  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          backgroundColor: "#f4f6f9",
        }}
      >
        <SideMenu />
        {/* Main content */}
        <div className="form-container">
          <Title title={"Add FAQ Content"}></Title>
          {message ? <Alert isError={isError} message={message} /> : null}
          <section className="content">
            <div className="container-fluid">
              {/* <!-- general form elements --> */}
              <div class="card card-primary">
                {/* <!-- form start --> */}
                <form>
                  <div class="card-body">
                    <div class="form-group">
                      <label htmlFor="category">Category</label>
                      <select
                        required
                        className="custom-select"
                        defaultValue="label"
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option disabled value="label">
                          Select Category
                        </option>
                        {catData.map((catOption) => (
                          <option
                            key={catOption.faqCategoriesID}
                            value={catOption.faqCategoriesID}
                          >
                            {catOption.faqCategories}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="question">Question</label>
                      <input
                        onChange={(e) => setQuestion(e.target.value)}
                        type="text"
                        class="form-control"
                        id="question"
                        placeholder="Enter FAQ Question"
                      />
                    </div>
                    <div class="form-group">
                      <label for="answer">Answer</label>
                      <ReactQuill
                        theme="snow"
                        value={answer}
                        onChange={handleEditorChange}
                      />
                    </div>
                  </div>
                  {/* <!-- /.card-body --> */}

                  <div class="card-footer">
                    <button onClick={onSubmitHandler} class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                  {/* <span style={{ color: isError ? "red" : "green" }}>
                {message ? getMessage() : null}
              </span> */}
                </form>
              </div>
              {/* <!-- /.card --> */}

              {/* <!-- general form elements --> */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default AddFAQ;
