import React, { useRef, useState, useEffect, useContext } from "react";
import useAuth from "../Hooks/useAuth";
import Alert from "../Components/Alert";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { API_URL } from "../Constants/APIURL";

const Login = () => {
  const { setAuth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {});

  useEffect(() => {
    setIsError(false);
  }, [email, pwd]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email && pwd) {
      const payload = {
        email: email,
        password: pwd,
      };
      try {
        const response = await fetch(`${API_URL}/loginAdmin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          setIsError(true);
          setMessage("Login Error");
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonRes = await response.json();

        setIsError(false);
        setMessage("Login success");
        const accessToken = jsonRes.token;
        const role = jsonRes.data.role;
        setAuth({ email, pwd, role, accessToken });
        navigate(from, { replace: true });
      } catch (error) {
        setIsError(true);
        setMessage("Unauthorized access");
        console.log("Login Error:", error);
      }
    }
  };

  return (
    <div className="container mt-5 h-100">
      <div className="row justify-content-center">
        <div className="col-md-6">
          {message ? <Alert isError={isError} message={message} /> : null}
          <div className="text-center my-5">
            <img
              src={require("../Assets/img/DailyCoffeeLogo.png")}
              alt="logo"
              width="100"
            />
          </div>
          <h2 className="mb-4 text-center">Daily Coffee App Portal</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="text"
                ref={userRef}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="form-control"
                id="email"
                autoComplete="off"
                name="email"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                autoComplete="off"
                id="password"
                name="password"
                onChange={(e) => setPwd(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
