import React, { useState, useEffect } from "react";

import SideMenu from "../Components/SideMenu";
import { API_URL } from "../Constants/APIURL";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
<link
  rel="stylesheet"
  href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
  integrity="sha384-GLhlTQ8iK4l5f5QlGx4FsqQDFHAnKw8x73+4N5giRTtX32w+0pLhx2r7ZmICha"
  crossorigin="anonymous"
/>;

const Otp = () => {
  const [phoneNum, setPhoneNum] = useState(null);
  const [otp, setOtp] = useState(null);

  useEffect(() => {
    document.title = "Otp";
  }, []);

  const retrieveOtp = async() => {
    const response = await fetch(`https://app.dailycoffee.com.my/retrieveOTP?phone=${phoneNum}`);
    const res = await response.json();
    const OTP = res.OTP;
    setOtp(OTP);
  };

  return (
    <div style={({ height: "100vh" }, { display: "flex" })}>
      <SideMenu />
      {/* Main content */}
      {/* <div className="content-wrapper"> */}
      {/* <div>
        <Title title={"Send Notification"}></Title>

      </div> */}
      <div className="form-container">
        <h2>OTP Retrieval</h2>
        <form>
          <div class="form-group">
            <label>Phone Number</label>
            <input
              name="phoneNum"
              className="form-control"
              onChange={(e) => setPhoneNum(e.target.value)}
            />
          </div>
        </form>
        <br></br>
        <button onClick={retrieveOtp} className="btn btn-primary">
          Get OTP
        </button>
        <br></br>
        <p>{otp}</p>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default Otp;
