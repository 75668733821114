import React, { useState } from "react";
import "../../App.css";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Alert from "../../Components/Alert";
import "react-quill/dist/quill.snow.css";
import SideMenu from "../../Components/SideMenu";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const AddAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [pwd, setPwd] = useState("");

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if ((email, phone, name, role, pwd)) {
      const payload = {
        email: email,
        phone: phone,
        name: name,
        role: role,
        pwd: pwd,
      };
      fetch(`${API_URL}/addAdmin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) {
            setIsError(true);
            setMessage("HTTP Upload Error");
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          setIsError(false);
          setMessage("Admin added successfully");
          setLoading(false);
        })
        .catch((error) => {
          setIsError(true);
          setMessage("Upload Error");
          console.error("Upload Error:", error);
          setLoading(false);
        });
    } else {
      setMessage("Field is empty");
      setIsError(true);
    }
  };

  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        backgroundColor: "#f4f6f9",
      }}
    >
      <SideMenu />
      {/* Main content */}
      <div className="form-container">
        <Title title={"Add Admin User"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}

        <section>
          {/* <!-- general form elements --> */}
          <div class="card card-primary">
            {/* <!-- form start --> */}
            <form>
              <div class="card-body">
                <div class="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="Enter name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    class="form-control"
                    id="email"
                    placeholder="Enter email"
                    required
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    class="form-control"
                    id="phone"
                    placeholder="Enter phone number"
                    required
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="role">Role</label>
                  <select
                    required
                    className="custom-select"
                    onChange={(e) => setRole(e.target.value)}
                    defaultValue="label"
                  >
                    <option disabled value="label">
                      Select Role
                    </option>
                    <option value="admin">Admin</option>
                    <option value="manager">Manager</option>
                    <option value="staff">Staff</option>
                  </select>
                </div>
                <div class="form-group">
                  <label htmlFor="phone">Password</label>
                  <input
                    onChange={(e) => setPwd(e.target.value)}
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="Enter password"
                    required
                  />
                </div>
              </div>
              {/* <!-- /.card-body --> */}

              <div class="card-footer">
                <button onClick={onSubmitHandler} class="btn btn-primary">
                  Submit
                </button>
              </div>
              {/* <span style={{ color: isError ? "red" : "green" }}>
                {message ? getMessage() : null}
              </span> */}
            </form>
          </div>
          {/* <!-- /.card --> */}

          {/* <!-- general form elements --> */}
        </section>
      </div>
    </div>
  );
};
export default AddAdmin;
