import React, { useState } from "react";
import "../../App.css";
import Footer from "../../Components/Footer";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Alert from "../../Components/Alert";
import "react-quill/dist/quill.snow.css";
import SideMenu from "../../Components/SideMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AWS from "../../Constants/aws-config";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const AddFooterBanner = () => {
  const [loading, setLoading] = useState(false);
  const [bannerType, setBannerType] = useState("");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerImage, setBannerImage] = useState([]);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setBannerImage(imageFile);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const onSubmitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("bannerType", bannerType);
    formData.append("bannerTitle",bannerTitle);
    formData.append("bannerImage", bannerImage);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);

        fetch(`${API_URL}/addFooterBanner`, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              setIsError(true);
              setMessage("HTTP Upload Error");
              setLoading(false);
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            //clear input fields in the form
            setIsError(false);
            setMessage("File uploaded successfully");
            setLoading(false);
          })
          .catch((error) => {
            setIsError(true);
            setMessage("Upload Error");
            console.error("Upload error:", error);
            setLoading(false);
          });
    
    
  };

  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }
  const getMessage = () => {
    const status = isError ? `Error` : `Success:`;
    return status + message;
  };
  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          backgroundColor: "#f4f6f9",
        }}
      >
        <SideMenu />
        {/* Main content */}
        <div className="form-container">
          <Title title={"Add Footer Banner"}></Title>
          {message ? <Alert isError={isError} message={message} /> : null}
          <section className="content">
            {/* <!-- general form elements --> */}
            <div class="card card-primary">
              {/* <!-- form start --> */}
              <form onSubmit={onSubmitHandler} >
                <div class="card-body">
                  <div class="form-group">
                    <label htmlFor="bannerType">Banner Type</label>
                    <select
                      required
                      className="custom-select"
                      defaultValue="label"
                      onChange={(e) => setBannerType(e.target.value)}
                    >
                      <option disabled value="label">
                        Select Banner Type
                      </option>
                      <option value="default">Default</option>
                      <option value="seasonal">Seasonal</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label htmlFor="bannertitle">Banner Title</label>
                    <input
                      onChange={(e) => setBannerTitle(e.target.value)}
                      type="text"
                      class="form-control"
                      id="newstitle"
                      placeholder="Enter banner title"
                    />
                  </div>
                  <div class="form-group">
                    <label htmlFor="bannertitle">Start Date</label>
                    <DatePicker
                      showIcon
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div class="form-group">
                    <label htmlFor="bannertitle">End Date</label>
                    <DatePicker
                      showIcon
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                  <div class="form-group">
                    <label htmlFor="image-input">File input</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          id="image-input"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Preview"
                        style={{ maxWidth: "100%", marginTop: "10px" }}
                      />
                    )}
                  </div>
                </div>
                {/* <!-- /.card-body --> */}

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">
                    Submit
                  </button>
                </div>
                {/* <span style={{ color: isError ? "red" : "green" }}>
                {message ? getMessage() : null}
              </span> */}
              </form>
            </div>
            {/* <!-- /.card --> */}

            {/* <!-- general form elements --> */}
          </section>
        </div>
      </div>
    </>
  );
};
export default AddFooterBanner;
