import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
//import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import { API_URL } from "../../Constants/APIURL";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { BarChart } from "@mui/x-charts/BarChart";
import "./Report.css";
import { getChartDimensions } from "../../Constants/chartUtils";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";
import Title from "../../Components/Title";
<link
  rel="stylesheet"
  href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
  integrity="sha384-GLhlTQ8iK4l5f5QlGx4FsqQDFHAnKw8x73+4N5giRTtX32w+0pLhx2r7ZmICha"
  crossorigin="anonymous"
/>;

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 4.8132256, // Replace with your desired center coordinates
  lng: 108.4752564,
};

const AppsalesReport = () => {
  //const [outletList, setOutletList] = useState([]);
  //const [outletName, setOutletName] = useState([]);
  const [outletSalesData, setOutletSalesData] = useState([]);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [detailsSales, setDetailsSales] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [loading, setLoading] = useState(false);

  //the outlet name need to use trim(), else there will be unintended spacing behind the outlet name
  const getSalesData = () => {
    setLoading(true);
    if (dateFrom == null || dateFrom == "" || dateTo == null || dateTo == "") {
      return null;
      setLoading(false);
    } else {
      fetch(`${API_URL}/getSalesData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dateFrom: dateFrom,
          dateTo: dateTo,
        }),
      }).then(async (response) => {
        const jsonRes = await response.json();
        if (response.status == 200) {
          var salesDataJSON = jsonRes.salesData;
          console.log(salesDataJSON);
          setDetailsSales(salesDataJSON);
          const uniqueSalesOutlets = salesDataJSON
            .map((order) => order.salesOutlet.trim())
            .filter((value, index, self) => self.indexOf(value) === index);

          //setOutletList(uniqueSalesOutlets)
          //const modifiedOutlets = uniqueSalesOutlets.map((outlet) => outlet.replace('Daily Coffee ', ''));

          //setOutletName(modifiedOutlets)

          const salesDataByOutlet = [];

          // Assign initial values to each outlet
          uniqueSalesOutlets.forEach((outlet) => {
            salesDataByOutlet.push({
              salesAmount: 0.0,
              salesCount: 0,
              outlet: outlet,
              outletName: outlet.replace("Daily Coffee ", ""),
            });
          });

          let total = 0;

          salesDataJSON.forEach((data) => {
            // salesDataByOutlet[data.salesOutlet].salesAmount += data.salesAmount;
            // salesDataByOutlet[data.salesOutlet].salesCount++;
            salesDataByOutlet.forEach((byOutlet) => {
              if (byOutlet.outlet.trim() == data.salesOutlet.trim()) {
                byOutlet.salesAmount = parseFloat(
                  (byOutlet.salesAmount + data.salesAmount).toFixed(2)
                );
                byOutlet.salesCount++;
                total = parseFloat((total + data.salesAmount).toFixed(2));
              }
            });
          });

          await setOutletSalesData(salesDataByOutlet);
          setTotalAmount(total);
          setLoading(false);
        }
      });
    }
  };

  const columns = [
    { field: "orderID", headerName: "Order ID", width: 70 },
    { field: "salesNo", headerName: "Sales No", width: 150 },
    { field: "userID", headerName: "User ID", width: 70 },
    { field: "salesOutlet", headerName: "Outlet", width: 250 },
    { field: "salesType", headerName: "Sales Type", width: 110 },
    { field: "salesStatus", headerName: "Sales Status", width: 130 },
  ];

  function getRowId(row) {
    return row.orderID;
  }

  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div style={({ height: "100%" }, { display: "flex" })}>
      <SideMenu />
      {/* Main content */}
      {/* <div className="content-wrapper"> */}
      {/* <div>
        <Title title={"Send Notification"}></Title>

      </div> */}
      <div className="form-container">
        <h2>In App Sales Report</h2>
        <section className="content">
          <div className="container-fluid">
            <form>
              <div class="form-group">
                <label>From</label>
                <input
                  type="date"
                  name="dateFrom"
                  className="form-control"
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label>To</label>
                <input
                  type="date"
                  className="form-control"
                  name="dateTo"
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </div>
            </form>
            <button onClick={getSalesData} className="btn btn-primary">
              Query
            </button>
            <br></br>

            {/* In App Sales*/}
            <div className="card" style={{ width: "100%", marginTop: "30px" }}>
              <div className="card-header">
                <div className="container">
                  <div className="row">
                    <div className="col-8 text-left">Sales Graph</div>
                    <div class="col-4 text-right">RM {totalAmount}</div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {outletSalesData.length > 0 ? (
                  <div style={{ width: "100%", height: "300px" }}>
                    <BarChart
                      dataset={outletSalesData}
                      xAxis={[
                        {
                          id: "barCategories",
                          dataKey: "outletName",
                          //data: outletName,
                          scaleType: "band",
                          tickLabelStyle: {
                            angle: 30,
                            textAnchor: "start",
                            fontSize: 9,
                          },
                        },
                      ]}
                      series={[
                        {
                          dataKey: "salesAmount",
                        },
                      ]}
                      width={getChartDimensions().width}
                      height={getChartDimensions().height}
                    />
                  </div>
                ) : (
                  <p>No Data Available</p>
                )}
              </div>
            </div>

            <DataGrid
              rows={detailsSales}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              getRowId={getRowId}
              pageSizeOptions={[10, 50, 100]}
              //checkboxSelection
              //onRowSelectionModelChange={getSelectedRow} //when checkbox is checked, will pass as param to the function
              slots={{ toolbar: GridToolbar }}
              sx={{ height: 1 / 2 }}
            />
          </div>
        </section>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default AppsalesReport;
