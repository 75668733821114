import React, { useState } from "react";
import "../../App.css";
import Footer from "../../Components/Footer";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Alert from "../../Components/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SideMenu from "../../Components/SideMenu";
import AWS from "../../Constants/aws-config";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const AddNews = () => {
  const [loading, setLoading] = useState(false);
  const [newsTitle, setTitle] = useState("");
  const [newsContent, setNewsContent] = useState("");
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState("");
  const [newsImage, setNewsImage] = useState("");
  const [previewImage, setPreviewImage] = useState(null);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setNewsImage(imageFile);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const handleContentChange = (value) => {
    setNewsContent(value);
  };

  const onSubmitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("newsTitle", newsTitle);
    formData.append("newsContent", newsContent);
    formData.append("newsImage", newsImage);

    fetch(`${API_URL}/addNews`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage("HTTP Upload Error");
          setLoading(false);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        //clear input fields in the form
        setIsError(false);
        setMessage("File uploaded successfully");
        setLoading(false);
      })
      .catch((error) => {
        setIsError(true);
        setMessage("Upload Error");
        setLoading(false);
        console.error("Upload error:", error);
      });
  };

  const getMessage = () => {
    const status = isError ? `Error` : `Success:`;
    return status + message;
  };

  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          backgroundColor: "#f4f6f9",
        }}
      >
        <SideMenu />
        {/* Main content */}
        <div className="main-container">
          <Title title={"Add News"}></Title>
          {message ? <Alert isError={isError} message={message} /> : null}
          <section className="content">
            {/* <!-- general form elements --> */}
            <div class="card card-primary">
              {/* <!-- form start --> */}
              <form onSubmit={onSubmitHandler}>
                <div class="card-body">
                  <div class="form-group">
                    <label htmlFor="newstitle">News Title</label>
                    <input
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      class="form-control"
                      id="newstitle"
                      placeholder="Enter news title"
                    />
                  </div>
                  <div class="form-group">
                    <label htmlFor="image-input">File input</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          id="image-input"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Preview"
                        style={{ maxWidth: "100%", marginTop: "10px" }}
                      />
                    )}
                  </div>
                  <div class="form-group">
                    <label for="newscontent">Content</label>
                    <ReactQuill
                      theme="snow"
                      value={newsContent}
                      onChange={handleContentChange}
                    />
                  </div>
                </div>
                {/* <!-- /.card-body --> */}

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">
                    Submit
                  </button>
                </div>
                {/* <span style={{ color: isError ? "red" : "green" }}>
                {message ? getMessage() : null}
              </span> */}
              </form>
            </div>
            {/* <!-- /.card --> */}

            {/* <!-- general form elements --> */}
          </section>
        </div>
      </div>
    </>
  );
};
export default AddNews;
