import React, { useState, useEffect } from "react";
import "../../App.css";
import AWS from "../../Constants/aws-config";

import Footer from "../../Components/Footer";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Table from "../../Components/Table";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import SideMenu from "../../Components/SideMenu";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const ManageNews = () => {
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    loadNews();
  }, []);

  //load news database
  const loadNews = () => {
    fetch(`${API_URL}/news`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          // throw new Error(`HTTP error: ${response.status}`);
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
          setLoading(false);
        }
        return response.json();
      })
      .then((newsData) => {
        setNewsData(newsData);
        console.log(newsData);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching news", error);
        setLoading(false);
      });
  };

  const handleDeleteNews = async (id, newsimg) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    //get the image key by removing the prefix url
    const newsImage = newsimg.replace(
      "https://dcapp-bucket.s3.ap-southeast-1.amazonaws.com/",
      ""
    );
    if (confirmDelete) {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/deleteNews?id=${id}`, {
          method: "DELETE",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ newsImage })
        });

        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
          setLoading(false);
          return;
        }

        // Update the state after successful deletion
        setNewsData((prevData) =>
          prevData.filter((item) => item.newsID !== id)
        );


        setIsError(false);

        setMessage(`News deleted successfully`);
        setLoading(false);
      } catch (error) {
        console.log("Error deleting news", error);
      }
    }
  };

  const handleEditNews = (newsID) => {
    navigate(`/editNews/${newsID}`);
  };

  const columns = [
    {
      field: "newsID",
      headerName: "ID",
      width: 80,
    },
    {
      field: "newsTitle",
      headerName: "Title",
      width: 400,
    },
    // {
    //   field: "News Content",
    //   selector: (row) => row.newsContent,
    //   sortable: true,
    //   width: "400px",
    //   wrap: true,
    //   style: {},
    // },
    {
      field: "newsImage",
      headerName: "News Image",
      width: 300,
      renderCell: (params) => (
        <img
          src={params.row.newsImage}
          style={{ width: "150px", height: "100px" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <button
            className="btn btn-outline-primary"
            style={{ marginRight: "10px" }}
            onClick={(e) => handleEditNews(params.row.newsID)}
          >
            Edit
          </button>
          <button
            className="btn btn-outline-danger"
            onClick={(e) =>
              handleDeleteNews(params.row.newsID, params.row.newsImage)
            }
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        backgroundColor: "#f4f6f9",
      }}
    >
      {/* Render only when data is available */}
      <SideMenu />
      {/* Main content */}
      <div className="form-container">
        <Title title={"Manage News"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}

        <section className="content">
          <div className="container-fluid">
            <div className="text-right mb-2">
              <button
                onClick={() => navigate("/addNews")}
                className="btn btn-primary text-right"
              >
                Add News
              </button>
            </div>
            <Table
              columns={columns}
              rows={newsData}
              id={"newsID"}
              rowHeight={150}
            ></Table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ManageNews;
